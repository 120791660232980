<template>
  <div class="convenience">
    <div class="search-container">
      <el-row style="line-height:48px">
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="0px">
             <el-col :span="8" v-if="false">
              <div class="input-wrapper">
                <el-input
                  @keyup.enter.native="searchData"
                  placeholder="请输入关键字"
                  v-model="form.searchValue"
                ></el-input>
                <img
                  @click="searchData"
                  class="input-image"
                  src="../../../../assets/images/input_search.png"
                  alt=""
                  srcset=""
                />
              </div>
            </el-col>
              <el-col :span="8" v-if="false">
                <el-form-item prop="mainDirection" label="上报类型" label-width="100px">
                  <el-select v-model="form.mainDirection" >
                    <el-option v-for="item in reportTpyeBox" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
          </el-form>
        </el-col>
        <el-col :span="4">
            <!-- <el-button  v-if="buttonAuth.includes('venueMana:venue:add')" @click="changeAddDialog(true)">新增</el-button> -->
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="deviceName"
          align="center"
          min-width="168"
					label="设备名称">
        </el-table-column>
         <el-table-column
         	prop="deviceCode"
          min-width="120"
          align="center"
					label="设备ID">
        </el-table-column>
        <el-table-column
          prop="deviceWarnType"
          min-width="168"
          align="center"
          label="上报类型"
        ></el-table-column>
        <el-table-column
          prop="deviceWarnValue"
          align="center"
          min-width="115"
          label="告警位"
        >
          <template slot-scope="scope">
            <div :class="scope.row.onlineStatus ===1 ? 'onLine': 'offLine'">{{ scope.row.onlineStatus===1 ? '在线' : '离线' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceWarnContent"
          min-width="168"
          align="center"
          label="告警内容"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          min-width="168"
          label="上报时间"
        ></el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import pageClass from "@/unit/pageClass";
import createDeleteMixin from '@/mixins/createDeleteMixin.js'

class IndexClass extends pageClass {
  constructor(type) {
    super();
    if (type === "form") {
      this.searchValue = ''
    }
  }
}

export default {
  mixins: [mixin, createDeleteMixin(apis.fitnessDeviceManage)],
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      reportTpyeBox: [], // 上报类型 下拉框
      tableData: [],
    };
  },
  mounted() {},
  activated() {},
  computed: {
    ...mapState(["Language"]),
  },
  methods: {
    // 清空按钮
    cleanForm() {
      this.$refs.form.resetFields();
    },
    // 查询
    searchData() {
      // TODO 新增确定后的接口
      const formData = {
        pageNum: this.form.pageNum,
        pageSize: this.form.pageSize,
      }
       this.$http.post(apis.getIotDeviceWarnList, formData).then((res) => {
        if (res.data.code === 0) {
          console.log("获取数据列表", res.data)
          this.tableData = res.data.rows;
          this.form.total = res.data.total
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.less";
.convenience {
   .search-container {
    .input-wrapper {
      width: 280px;
      position: relative;
      .input-image {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        }
      }
    }
  .page-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}

.allOff-wrapper{
  display: inline-block;
  margin-right: 38px;
  .allAndOff{
    margin-right: 10px;
  }
}
.btn-wrapper {
  display: inline-block;
  margin-right: 32px;
}
.oper-text{
  color: #439BFF;
  font-size: 12px;
}
.line{
  display: inline-block;
  margin:0 8px;
  width: 1px;
  height: 13px;
  background: #D8D8D8;
}
.onLine{
  color: #6DD400;
}
.offLine{
  color: #E02020;
}


</style>
